@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");
body {
  font-family: "Ubuntu", sans-serif;
}

.spa-app {
  text-align: center;
  display: block;
  flex-direction: column;
  min-height: 600px;
}

.spa-content {
  flex: 1 1 auto;
  display: flex;
  direction: row;
  min-height: 600px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiBadge-root {
  font-size: 1.5rem 
}

.MuiSvgIcon-root {
  font-size: 1.8rem !important;
}

.MuiChip-label {
  font-size: 1.1rem !important;
}

.MuiChip-root {
  height: 26px !important;
}

.MuiAutocomplete-tag {
 margin: 1px !important;
}

.MuiAutocomplete-inputRoot {
 min-height :39px;
}
.MuiTableCell-sizeSmall{
 padding: 3px 24px 3px 16px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fefeff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(37, 35, 35);
}

.App-link {
  color: #61dafb;
}

.routes-container {
  width: 100%;
  position: relative;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
