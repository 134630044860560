@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap);
html {
  font-size: 1.5 rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", sans-serif,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page-container > .content {
  padding: 0 30px 20px;
}
.MuiTab-root{
  font-size: 1.5rem;
}
.MuiTableCell-root{
  font-size: 14px !important;
  font-family: "Ubuntu" !important;
}
.MuiTableCell-head {
 font-weight: bold !important;
 font-family: "Ubuntu" !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.handy-scroll{bottom:0;min-height:17px;overflow:auto;position:fixed}.handy-scroll div{height:1px;overflow:hidden;pointer-events:none}.handy-scroll div:before{content:"\A0"}.handy-scroll,.handy-scroll div{font-size:1px;line-height:0;margin:0;padding:0}.handy-scroll-hidden{bottom:9999px}.handy-scroll-hidden div:before{content:"\A0\A0"}.handy-scroll-viewport{position:relative}.handy-scroll-body{overflow:auto}.handy-scroll-viewport .handy-scroll{left:0;position:absolute}.handy-scroll-hoverable .handy-scroll{opacity:0;transition:opacity .5s .3s}.handy-scroll-hoverable:hover .handy-scroll{opacity:1}
body {
  font-family: "Ubuntu", sans-serif;
}

.spa-app {
  text-align: center;
  display: block;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 600px;
}

.spa-content {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  direction: row;
  min-height: 600px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiBadge-root {
  font-size: 1.5rem 
}

.MuiSvgIcon-root {
  font-size: 1.8rem !important;
}

.MuiChip-label {
  font-size: 1.1rem !important;
}

.MuiChip-root {
  height: 26px !important;
}

.MuiAutocomplete-tag {
 margin: 1px !important;
}

.MuiAutocomplete-inputRoot {
 min-height :39px;
}
.MuiTableCell-sizeSmall{
 padding: 3px 24px 3px 16px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fefeff;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(37, 35, 35);
}

.App-link {
  color: #61dafb;
}

.routes-container {
  width: 100%;
  position: relative;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

